import React from "react"
import Layout from "./layout"
import { Link, graphql } from "gatsby"
import BlogList from "../components/blogList"
import Seo from "../components/seo"
import {GatsbyImage, getImage } from "gatsby-plugin-image"
import Image from "../components/image"
import parse, {} from "html-react-parser"

import * as post from "../components/css/blogPost.module.scss"

export const query = graphql`
  query($slug: String!) {
    site: site {
      id
      siteMetadata {
        author
        backgroundColor
        description
        domain
        shortName
        siteLanguage
        title
        url
        ogpImage
      }
    }

    data: allWpPost(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          title
          excerpt
          content
          slug
          date(formatString: "YYYY-MM-DD")
          featuredImage {
            node {
              localFile {
                url
                childImageSharp {
                  fluid(maxWidth: 1024)  {
                    ...GatsbyImageSharpFluid
                  }
                  gatsbyImageData (
                    width: 800
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          categories {
            nodes {
              name
              slug
            }
          }
        }
      }
    }
  }
`

const blogPost = (props) => {
  const { data } = props.data

  const article = data.edges[0].node
  const title = article.title
  const postdate = article.date
  const excerpt = article.excerpt
  const content = article.content
  const cate = article.categories.nodes
  const id = article.id

  const isFeaturedImage = (article.featuredImage) ? true : false
  const image = (isFeaturedImage) ? getImage(article.featuredImage.node.localFile.childImageSharp.gatsbyImageData) : ""
  const ogpImage = (isFeaturedImage) ? article.featuredImage.node.localFile.childImageSharp.fluid.src : ""
  
  const replaceImgCode = node => {
    if (node.name && node.attribs.class) {
      if (node.name === "img" && node.attribs.class.includes(`wp-image`)) {
        let imgSrc = node.attribs.src
        if (imgSrc.match(/-[^-]*(?=\.)/g) !== null) {
          let foundStr = imgSrc.match(/-[^-]*(?=\.)/g)[0] // ex. 20220111-01-figure01-1024x675.jpg 最後の-から拡張子の手前までを抽出
          if (foundStr.includes('x')) {
            imgSrc = node.attribs.src.replace(/-[^-]*(?=\.)/,'') 
          }
        }
        return (
          <Image filename={`${imgSrc}`} alt={`${node.attribs.class}`} />
        )
      }
    }
  }

  return (
    <Layout>
      <Seo title={title}
          ogpImage={ogpImage}
          url={`https://wonderwall.net/articles/${article.slug}/`}
          description='ワンダウォール・ネットの記事' />
      <div id="blogPost" className={`${post.wrap} single cid-${id} cate-${cate[0].slug}`} data-cate={`${cate[0].slug}`} >
        <div className={`${post.primary} content-area`}>
          <main class="site-main" role="main">
            <article class="entry" itemProp="articleBody">

              <header className={`${post.entryHeader} entry-header`}>
                <div className={`${post.entryMeta} entry-meta`}>
                  <div className={`${post.categoryName} category-name`}>
                    {Object.keys(cate).map((data) => {
                      return (
                        <Link to={`/articles/${cate[data].slug}`} key={data}>
                          {cate[data].name}
                        </Link>
                      )
                    })}
                  </div>
                  <span className={`${post.postedOn} posted-on`}>{postdate}</span>
                </div>
                <h1 className={`${post.entryTitle} entry-title`}>{title}</h1>
                <div
                  class="fb-like"
                  data-share="true"
                  data-width="450"
                  data-show-faces="true">
                </div>
              </header>

              <section className={`${post.excerptCopy} excerpt-copy`}>{excerpt}</section>

              {isFeaturedImage && (
                <figure className={`${post.featuredImageFrame} single-featured-image-header`}>
                  <GatsbyImage image={image} className={`${post.featuredImage}`} alt={title} />
                </figure>
              )}
              {!isFeaturedImage && (
                <figure className={`${post.featuredImageFrame} single-featured-image-header`}>
                  <Image filename={`${image}`} alt={title} className={`${post.featuredImage}`} />
                </figure>
              )}

              <section
                className={`${post.entryContent} entry-content`}>
                {parse(content, { replace: replaceImgCode })}
              </section>
            </article>
          </main>
        </div>
      </div>
      <BlogList type={cate[0].slug} />
    </Layout>
  )
}

export default blogPost